import {
  AUTH_USER, IS_NEW_USER, LOGOUT,
} from '../Constants/constants';
import { AuthContextInterface } from '../Store/store';
import Cookies from 'js-cookie';

interface AuthReducerInterface {
    type?: string;
    payload?: AuthContextInterface;
}

const AuthReducer = (state: AuthContextInterface, action: AuthReducerInterface) => {
  switch (action.type) {
    case AUTH_USER:
      Cookies.set('user_is_authd', 'true', { expires: 365 });
      Cookies.set('user_id', action.payload, { expires: 365 });

      return { ...state, isAuthd: true, userId: action.payload };
    case LOGOUT:
      Cookies.remove('user_is_authd');
      Cookies.set('user_is_new', 'true');
      Cookies.remove('user_id');

      return { ...state, isAuthd: false, userId: null };
    default:
      return state;
  }
};

export default AuthReducer;
