import { Product } from '../../types/types';
import AuthReducer from '../Reducers/authReducer';
import Reducer from '../Reducers/reducer';
import { SearchReducer } from '../Reducers/searchReducer';
import React, { createContext, useReducer } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

export interface ContextInterface {
  isModalOpened: boolean;
  cart: Product[];
  totalPrice: number;
  products: Product[];
}

export interface AuthContextInterface {
  isAuthd: boolean;
  userId: string;
}

export interface SearchContextInterface {
  searchProducts: Product[];
}

const InitialState = {
  cart: [],
  totalPrice: 0,
  products: [],
  isModalOpened: false,
};

const AuthInitialState = {
  isAuthd: false,
  userId: null,
};

const SearchInitialState = {
  searchProducts: [],
};

const Store = ({ children }: HTMLElement) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [authState, authDispatch] = useReducer(AuthReducer, AuthInitialState);
  const [searchState, searchDispatch] = useReducer(SearchReducer, SearchInitialState);

  const theme = createMuiTheme({
    overrides: {
      // ...
      MuiBadge: {
        colorPrimary: {
          color: '#ffffff',
          backgroundColor: '#EE7238',
        },
      },
      MuiSlider: {
        root: {
          color: '#EE7238',
        },
      },
      MuiChip: {
        root: {
          height: '34px',
          fontSize: '14px',
        },
        colorPrimary: {
          color: '#ffffff',
        },
        outlined: {
          borderColor: '#eaeaea',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#eaeaea',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: '#eaeaea',
        },
      },
      MuiTab: {
        root: {
          fontWeight: 300,
        },
        textColorPrimary: {
          color: '#8E8E8E',
        },
      },
      MuiBackdrop: {
        root: {
        },
      },
      MuiButton: {
        outlined: {
          borderColor: '#eaeaea',
        },
        containedPrimary: {
          color: '#ffffff',
          boxShadow: 'rgb(153 218 16 / 61%) 0px 4px 13px',
        },
        textSecondary: {
          fontWeight: 300,
        },
        textPrimary: {
          fontWeight: 300,
        },
      },
      // ...
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: [
        'Arimo',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: { main: '#84B420' },

    },
  }, ruRU);

  return (
    <ThemeProvider theme={theme}>
      <SearchContext.Provider value={{ searchState, searchDispatch }}>
        <AuthContext.Provider value={{ authState, authDispatch }}>
          <Context.Provider value={{ state, dispatch }}>
            {children}
          </Context.Provider>
        </AuthContext.Provider>
      </SearchContext.Provider>
    </ThemeProvider>
  );
};

export const Context = createContext<ContextInterface>(InitialState);
export const AuthContext = createContext<AuthContextInterface>(AuthInitialState);
export const SearchContext = createContext<SearchContextInterface>(SearchInitialState);
export default Store;
