module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PZFVZFF","defaultDataLayer":{"type":"object","value":{}},"includeInDevelopment":true,"dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3117efb3d821461fb16c9b677490e53d@o507345.ingest.sentry.io/5598314","autoSessionTracking":true,"sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Хочу Суши — доставка в Севастополе","short_name":"Хочу Суши","start_url":"/","lang":"ru","background_color":"#ffffff","theme_color":"#84B420","display":"minimal-ui","icon":"src/images/favicon.svg","shortcuts":[{"name":"Главная","short_name":"Главная","description":"Главная страница нашего сайта","url":"/"},{"name":"Корзина","short_name":"Корзина","description":"Место где вы храните ваши суши.","url":"/cart"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1197447c086b1d6eace9f8f08612be2e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
