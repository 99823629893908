import { ADD_SEARCH_RESULTS } from "../Constants/constants"
import { SearchContextInterface } from "../Store/store"

interface SearchReducerInterface {
    type?: string;
    payload?: SearchContextInterface;
}

export const SearchReducer = (state: SearchContextInterface, action: SearchReducerInterface) => {
    switch (action.type) {
        case ADD_SEARCH_RESULTS:
        return {...state, searchProducts: action.payload}
    }
}