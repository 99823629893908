export const ADD_OR_INCREMENT_PRODUCT_TO_CART = 'ADD_OR_INCREMENT_PRODUCT_TO_CART';
export const REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID = 'REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID';
export const MODAL_OPENED = 'MODAL_OPENED';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_CART_FROM_LOCALSTORAGE = 'ADD_CART_FROM_COOKIE';
export const ADD_PRODUCTS_TO_STATE = 'ADD_PRODUCTS_TO_STATE';
export const ADD_PRODUCTS_TO_CART_FROM_HISTORY = 'ADD_PRODUCTS_TO_CART_FROM_HISTORY';
export const REMOVE_ITEMS_FROM_CART = 'REMOVE_ITEMS_FROM_CART';
export const REPLACE_CART = 'REPLACE_CART';

export const AUTH_USER = 'AUTH_USER';
export const LOGOUT = 'LOGOUT';
export const IS_NEW_USER = 'IS_NEW_USER';

export const ADD_SEARCH_RESULTS = 'ADD_SEARCH_RESULTS';
