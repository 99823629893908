import { ModificatorsType, ModifsSelectedType, Product } from '../types/types';

export const isBrowser = typeof window !== 'undefined';

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const pluralize = (count, words) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return `${count} ${words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]]}`;
};

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const isArrayEmpty = (array: any[]) => {
  if (!Array.isArray(array) || !array.length || array.length === 0) {
    return true;
  }

  return false;
};

export const getRandomInt = () => Math.floor(1000 + Math.random() * 9000);

export const isTodayIsWeekend = () => {
  const now = new Date();
  const dayOfWeek = now.getDay();

  return (dayOfWeek === 6) || (dayOfWeek === 0);
};

export const getFullModificatorPriceForProduct = (modifs) => {
  const modifsFullPrice = (modifs.length > 1)
    ? modifs.reduce((a, v) => a + (v.price * v.amount), 0)
    : modifs[0].price;

  return modifsFullPrice;
};

const getModifsWithReplacedPrice = (
  modifsSelected: ModifsSelectedType[],
  modificatorsFromState: ModificatorsType[],
) => modifsSelected
  .map((cartMod) => {
    const findMod = modificatorsFromState
      .find((stateMod) => stateMod.iikoModifID === cartMod.iikoID);
    if (findMod) {
      return {
        ...cartMod,
        price: findMod.Price || null,
      };
    }
    return null;
  }).filter((modif) => modif);

export const serializeCart = (cart: Product[]) => JSON.stringify(cart.map((prod) => ({
  OptionSelectedID: prod.OptionSelectedID,
  iikoID: prod.iikoID,
  Name: prod.Name,
  productQuantity: prod.productQuantity,
  modifsSelected: prod.modifsSelected,
})));

export const getCartWithReplacedPricesFromState = (cart: Product[], products: Product[]) => {
  const updated = [];

  cart.forEach((cartProd) => {
    let temp;

    // check for options
    if (cartProd.OptionSelectedID) {
      const prodFromState = products
        .find((stateProd) => stateProd.Options
          .some((options) => options.iikoID === cartProd.OptionSelectedID));

      if (!prodFromState) {
        return;
      }

      const currentOptionFromState = prodFromState.Options
        .find((option) => option.iikoID === cartProd.OptionSelectedID);

      if (!currentOptionFromState) {
        return;
      }

      temp = { ...prodFromState };

      temp = {
        ...temp,
        Price: currentOptionFromState.Price,
        OptionSelectedID: cartProd.OptionSelectedID,
        productQuantity: cartProd.productQuantity,
        Name: cartProd.Name,
        modifsSelected: cartProd.modifsSelected,
      };
      // check for modifs
      if (temp.modifsSelected) {
        const modifsWithNewPrice = getModifsWithReplacedPrice(temp.modifsSelected,
          currentOptionFromState.Modificators);

        const modifsFullPrice = getFullModificatorPriceForProduct(modifsWithNewPrice);
        temp = { ...temp, Price: temp.Price + modifsFullPrice, modifsSelected: modifsWithNewPrice };
      }
    } else {
      // if simple product without options

      const prodFromState = products.find((stateProd) => cartProd.iikoID === stateProd.iikoID);

      if (!prodFromState) {
        return;
      }

      temp = { ...prodFromState };

      temp = {
        ...temp,
        productQuantity: cartProd.productQuantity,
        Name: cartProd.Name,
        modifsSelected: cartProd.modifsSelected,
      };

      // check for modifs
      if (temp.modifsSelected) {
        // eslint-disable-next-line prefer-spread
        const allModifsInProduct: ModificatorsType[] = []
          .concat
          .apply([], prodFromState.GroupOfMods.map((v) => v.Mod));

        const modifsWithNewPrice = getModifsWithReplacedPrice(temp.modifsSelected,
          allModifsInProduct);

        const modifsFullPrice = getFullModificatorPriceForProduct(modifsWithNewPrice);

        temp = {
          ...temp,
          Price: temp.Price + modifsFullPrice,
          modifsSelected: modifsWithNewPrice,
        };
      }
    }

    updated.push(temp);
  });

  return updated;
};

export const recalculateTotalPrice = (products: Product[]) => {
  const recalcPrice = products
    .map((val) => Number(val.Price) * val.productQuantity)
    .reduce((acc, cur) => acc + cur, 0);

  return recalcPrice;
};
