import Store from './src/Utils/Store/store';
import React from 'react';

export const wrapRootElement = ({ element }) => (
  <Store>
    {element}
  </Store>
);

// export const onServiceWorkerUpdateReady = () => window.location.reload();
