/* eslint-disable no-case-declarations */
import {
  ADD_CART_FROM_LOCALSTORAGE,
  ADD_OR_INCREMENT_PRODUCT_TO_CART,
  ADD_PRODUCT_TO_CART,
  ADD_PRODUCTS_TO_STATE,
  MODAL_OPENED,
  REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID,
  REMOVE_ITEMS_FROM_CART,
  REPLACE_CART,
} from '../Constants/constants';
import { ContextInterface } from '../Store/store';
import { getCartWithReplacedPricesFromState, recalculateTotalPrice, serializeCart } from '../utils';

const dataLayer = typeof window !== 'undefined' ? window.dataLayer : null;

interface ReducerActionInterface {
  type?: string;
  payload?: ContextInterface;
}

const Reducer = (state: ContextInterface, action: ReducerActionInterface) => {
  const { payload } = action;
  let onCart;
  let isOption;
  let total;

  switch (action.type) {
    case ADD_CART_FROM_LOCALSTORAGE:
      const updatedCart = getCartWithReplacedPricesFromState(payload, state.products);
      total = recalculateTotalPrice(updatedCart);

      return { ...state, totalPrice: total, cart: updatedCart };

    case REPLACE_CART:
      const recalcPrice = recalculateTotalPrice(payload);

      localStorage.setItem('cart', serializeCart(payload));

      return { ...state, totalPrice: recalcPrice, cart: payload };

    case ADD_OR_INCREMENT_PRODUCT_TO_CART:
      isOption = payload.OptionSelectedID;
      onCart = state.cart.find((val) => (isOption
        ? val.OptionSelectedID === payload.OptionSelectedID
        : val.iikoID === payload.iikoID));

      if (onCart) {
        let cartWithItemIncr;
        if (isOption) {
          cartWithItemIncr = state.cart
            .map((val) => (val.OptionSelectedID === payload.OptionSelectedID
              ? ({ ...val, productQuantity: val.productQuantity + 1 })
              : val));
        } else {
          cartWithItemIncr = state.cart
            .map((val) => (val.iikoID === payload.iikoID
              ? ({ ...val, productQuantity: val.productQuantity + 1 })
              : val));
        }
        total = state.totalPrice + Number(payload.Price);

        dataLayer.push({
          ecommerce: {
            add: {
              products: [
                {
                  id: isOption ? payload.OptionSelectedID : payload.iikoID,
                  name: payload.Name,
                  price: payload.Price,
                  quantity: 1,
                },
              ],
            },
          },
        });

        localStorage.setItem('cart', serializeCart(cartWithItemIncr));

        return { ...state, totalPrice: total, cart: cartWithItemIncr };
      }
      const prodQuantity = payload.productQuantity && payload.productQuantity > 1
        ? payload.productQuantity
        : 1;
      const cart = [...state.cart, { ...payload, productQuantity: prodQuantity }];

      dataLayer.push({
        ecommerce: {
          add: {
            products: [
              {
                id: isOption ? payload.OptionSelectedID : payload.iikoID,
                name: payload.Name,
                price: payload.Price,
                quantity: prodQuantity,
              },
            ],
          },
        },
      });

      localStorage.setItem('cart', serializeCart(cart));

      return { ...state, totalPrice: state.totalPrice + Number(payload.Price), cart };

    // заменить продукт
    case ADD_PRODUCT_TO_CART:
      isOption = payload.OptionSelectedID;
      const isThereModifs = (val) => val.modifsSelected && val.modifsSelected.length > 0;
      const isModifsEqual = (cartProduct) => isThereModifs(cartProduct) && JSON.stringify(payload.modifsSelected) === JSON.stringify(cartProduct.modifsSelected);

      onCart = state.cart.find((val) => (isOption
        ? val.OptionSelectedID === payload.OptionSelectedID && (isThereModifs(payload) && isModifsEqual(val))
        : isThereModifs(payload) ? isModifsEqual(val) : val.iikoID === payload.iikoID));

      // если есть в корзине, тогда перенести значения
      if (onCart) {
        let cartWithItemChanged;
        // Если это опция
        if (isOption) {
          cartWithItemChanged = state.cart
            .map((val) => (val.OptionSelectedID === payload.OptionSelectedID
              ? ({ ...payload })
              : val));
        } else {
          cartWithItemChanged = state.cart
            .map((val) => (val.iikoID === payload.iikoID
              ? ({ ...payload })
              : val));
        }
        const recalcPrice = recalculateTotalPrice(cartWithItemChanged);

        localStorage.setItem('cart', serializeCart(cartWithItemChanged));
        return { ...state, totalPrice: recalcPrice, cart: cartWithItemChanged };
      }

      // иначе просто запушить
      localStorage.setItem('cart', serializeCart([...state.cart, payload]));
      return {
        ...state,
        totalPrice: state.totalPrice + (Number(payload.Price) * payload.productQuantity),
        cart: [...state.cart, payload],
      };

    case REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID:
      const prod = state.cart.find((val) => val.iikoID === payload || val.OptionSelectedID === payload);

      if (prod.productQuantity > 1) {
        let cartWithItemRemoved;
        if (prod.OptionSelectedID) {
          cartWithItemRemoved = state.cart
            .map((val) => (val.OptionSelectedID === prod.OptionSelectedID
              ? ({ ...val, productQuantity: val.productQuantity - 1 })
              : val));
        } else {
          cartWithItemRemoved = state.cart
            .map((val) => (val.iikoID === prod.iikoID
              ? ({ ...val, productQuantity: val.productQuantity - 1 })
              : val));
        }
        dataLayer.push({
          ecommerce: {
            remove: {
              products: [
                {
                  id: prod.OptionSelectedID ? prod.OptionSelectedID : prod.iikoID,
                  name: prod.Name,
                  price: prod.Price,
                  quantity: 1,
                },
              ],
            },
          },
        });

        localStorage.setItem('cart', serializeCart(cartWithItemRemoved));
        return {
          ...state,
          totalPrice: state.totalPrice - Number(prod.Price),
          cart: cartWithItemRemoved,
        };
      } else {
        const cart = [...state.cart];
        const prodIndex = cart.findIndex((val) => val.iikoID === prod.iikoID || val.OptionSelectedID === payload);

        dataLayer.push({
          ecommerce: {
            remove: {
              products: [
                {
                  id: prod.OptionSelectedID ? prod.OptionSelectedID : prod.iikoID,
                  name: prod.Name,
                  price: prod.Price,
                  quantity: 1,
                },
              ],
            },
          },
        });

        cart.splice(prodIndex, 1);

        localStorage.setItem('cart', serializeCart(cart));
        return { ...state, totalPrice: state.totalPrice - Number(prod.Price), cart };
      }

    case ADD_PRODUCTS_TO_STATE:
      return { ...state, products: payload };

    case REMOVE_ITEMS_FROM_CART:
      localStorage.removeItem('cart');

      return { ...state, totalPrice: 0, cart: [] };

    case MODAL_OPENED:
      return {
        ...state, isModalOpened: payload,
      };

    default:
      return state;
  }
};

export default Reducer;
